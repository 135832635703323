import React, { useEffect, useState } from "react"
import "./scss/PrivacyPolicyStatement.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from 'gatsby';

export default function SM() {
  const data = useStaticQuery(graphql`
  query MyQuery16 {
    allContentfulFlexBoxStyle {
      edges {
        node {
          bodyText {
            raw
          }
          callToAction
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          subTitle
          title
        }
      }
    }
  }
  `)

  useEffect(() => {
    Aos.init({duration: 1000});
  }, []);

  return (
    <div className="contentFeaturep">
      <div className={'textContainerp'}>
        <p className={'titlep'}>Site Map</p>
        <br></br>
        <p className="links">
            <a href="https://www.thefreshkid.com" class="link1">
                Home
            </a>
        </p>
        <p className="links">
            <a href="https://thefreshkid.com/about/" class="link1">
                The Fresh Kid
            </a>
        </p>
        <p className="links">
            <a href="https://thefreshkid.com/my-heritage/" class="link1">
                My Heritage
            </a>
        </p>
        <p className="links">
            <a href="https://thefreshkid.com/luxury-customer-sentimentanalysis/" class="link1">
                Luxury Customer Sentiment Analysis
            </a>
        </p>
        <p className="links">
            <a href="https://thefreshkid.com/luxury-keynotespeaker/" class="link1">
                Public Speaking
            </a>
        </p>
        <p className="links">
            <a href="https://thefreshkid.com/nuheritage/" class="link1">
                Nú Heritage
            </a>
        </p>
        <p className="links">
            <a href="https://thefreshkid.com/luxury-thoughleadership/" class="link1">
                Luxe
            </a>
        </p>
        <p className="links">
            <a href="https://thefreshkid.com/luxury-circulareconomy/" class="link1">
                Fashion Tech Report
            </a>
        </p>
        <p className="links">
            <a href="https://thefreshkid.com/contactme/" class="link1">
                Contact Me
            </a>
        </p>
        <p className="links">
            <a href="https://thefreshkid.com/PrivacyPolicy/" class="link1">
                Privacy Policy
            </a>
        </p>
      </div>
    </div>
  )
}