import * as React from "react"
// import "./index.css"

import SM from "../components/SiteMapping"
import Layout from "../components/Layout"

function SiteMap({ data }) {
  return (
    <Layout>
      <SM />
    </Layout>
  )
}

export default SiteMap